<template>

  <!-- Modal Payment Provider  --->
  <app-modal :show="showFormPaymentProvider" @close="closeFormPaymentProvider">
    <template v-slot:title>
      {{ $t('payment.menu.payment_gateway') }}
    </template>

    <template v-slot:body>
      <app-alert-error class="p-4 bg-error-badge mb-5" v-if="errorsPaymentMethod.business_id"
        :message="errorsPaymentMethod.business_id[0]" />

      <app-form-payment-gateway v-model="payment_gateway_method_application.select_payment_gateway"
        :disabled="selectPaymentGatewayApplication?._id" />

      <div v-if="payment_gateway_method_application.select_payment_gateway">
        <div class="pt-4 pb-4">{{ $t('payment.menu.payment_method') }}</div>

        <app-form-payment-gateway-method
          :payment_gateway_id="payment_gateway_method_application.select_payment_gateway?.id ?? 0"
          v-model="payment_gateway_method_application.select_payment_method"
          :disabled="selectPaymentGatewayApplication?._id" />
      </div>

      <div v-if="payment_gateway_method_application.select_payment_method">
        <p class="pt-4 pb-4">{{ $t('payment.menu.payment_channel') }}</p>

        <app-form-payment-gateway-method-channel
          :payment_method_id="payment_gateway_method_application.select_payment_method.payment_method_id ?? 0"
          v-model="payment_gateway_method_application.select_payment_channel"
          :disabled="selectPaymentGatewayApplication?._id" />
      </div>

      <div v-if="payment_gateway_method_application.select_payment_channel">
        <p class="pt-2 pb-2">{{ $t('payment.menu.action') }}</p>
        <app-form-payment-gateway-application-status
          v-model="payment_gateway_method_application.select_payment_gateway_application_status" />
      </div>

      <app-button :showf70Icon="false" class="mt-4 pt-2 pb-2" @onClick="submitPaymentMethodApplication">
        {{
            this.payment_gateway_method_application.select_payment_gateway_application_status?.id ==
              PAYMENT_GATEWAY_APPLICATION.APPROVED
              ? $t('general.next')
              : $t('general.submit')
        }}
      </app-button>
    </template>
  </app-modal>

  <!-- Modal Business Payment Provider  --->
  <app-modal :show="showFormBusinessPaymentProvider" @close="closeFormBusinessPaymentProvider" :showClose="false">
    <template v-slot:title>
      <div class="flex justify-between">
        <img :src="selectPaymentGatewayApplication?.payment_gateway_method?.payment_gateway?.image_url" alt=""
          class="w-24"
          @click="showPaymentGatewayApplication(selectPaymentGatewayApplication), showFormBusinessPaymentProvider = false" />
        <span class="flex flex-row items-center space-x-2">
          <img :src="selectPaymentGatewayApplication?.payment_gateway_method?.payment_method?.image_url" alt="" />
          <p class="text-xs">
            {{ selectPaymentGatewayApplication?.payment_gateway_method?.payment_method?.name }}
          </p>
        </span>
      </div>
      <div class="my-5 flex flex-row items-center space-x-3">
        <p class="text-gray-400 text-sm">
          {{ $t('payment.settings.payment_provider.channel_used') }}
        </p>
        <div>
          <app-logo-senangpay class="mb-1"
            v-if="selectPaymentGatewayApplication?.payment_gateway_method?.channel == PAYMENT_CHANNEL.SENANG_PAY" />
          <app-logo-gobiz class="w-20"
            v-else-if="selectPaymentGatewayApplication?.payment_gateway_method?.channel == PAYMENT_CHANNEL.GO_BIZ" />
          <app-logo-stripe class="h-5"
            v-else-if="selectPaymentGatewayApplication?.payment_gateway_method?.channel == PAYMENT_CHANNEL.STRIPE" />
          <app-logo-iserve class="h-3"
            v-else-if="selectPaymentGatewayApplication?.payment_gateway_method?.channel == PAYMENT_CHANNEL.ISERVE" />
          <p class="text-sm" v-else>{{ selectPaymentGatewayApplication?.payment_gateway_method?.channel ?? "" }}</p>
        </div>
      </div>
      <hr />
      <app-tab :tabs="business_payment_provider_tabs" :selected_tab="selectBusinessPaymentProviderTab"
        @tab-changed="selectBusinessPaymentProviderTab = $event" />
    </template>

    <template v-slot:body>

      <!-- Business Payment Gateway Method  --->

      <div class="space-y-3" v-if="selectBusinessPaymentProviderTab == this.TAB.CHARGES">
        <app-form-input id="settlement_day" name="settlement_day" type="number"
          v-model="business_payment_gateway_method.settlement_day" :errorMessage="errorsPayment.settlement_day"
          :numberOnly="true" :maxlength="3" :labelText="
            $t('payment.menu.settlement_day')
          " />

        <app-form-select id="charges_type" name="charges_type" type="number"
          :labelText="$t('payment.menu.charges_type')" :datas="chargesType" :loading="loadingChargesType"
          :allowFilter="false" :errorMessage="errorsPayment.charges_type"
          v-model="business_payment_gateway_method.charges_type" v-on:retry="fetchChargesType" />

        <app-form-input v-if="business_payment_gateway_method.charges_type != null && business_payment_gateway_method.charges_type?.type != PAYMENT_CHARGE_TYPE.PERCENTAGE"
          id="fixed_charges" name="fixed_charges" step=".01" type="number" v-model="business_payment_gateway_method.fixed_charges"
          :errorMessage="errorsPayment.fixed_charges" :labelText="
            $t('payment.menu.fixed_charges') +
            ' (' +
            $t('payment.menu.template') +
            selectPaymentGatewayApplication?.payment_gateway_method.template_fixed_charges +
            ')'
          " />

        <app-form-input v-if="business_payment_gateway_method.charges_type != null && business_payment_gateway_method.charges_type?.type != PAYMENT_CHARGE_TYPE.FIXED"
          id="percentage_charges" name="percentage_charges" step=".01" type="number"
          v-model="business_payment_gateway_method.percentage_charges" :errorMessage="errorsPayment.percentage_charges"
          :labelText="
            $t('payment.menu.percentage_charges') +
            ' (' +
            $t('payment.menu.template') +
            selectPaymentGatewayApplication?.payment_gateway_method?.template_percentage_charges +
            ')'
          " />

        <app-form-input v-if="business_payment_gateway_method.charges_type != null && business_payment_gateway_method.charges_type?.type != PAYMENT_CHARGE_TYPE.FIXED"
          id="fixed_min_charges" name="fixed_min_charges" step=".01" type="number"
          v-model="business_payment_gateway_method.fixed_min_charges"
          :errorMessage="errorsPayment.fixed_min_charges" :labelText="
            $t('payment.menu.fixed_min_charges', { charge_type : business_payment_gateway_method.charges_type?.name }) +
            ' (' +
            $t('payment.menu.template') +
            selectPaymentGatewayApplication?.payment_gateway_method.template_fixed_min_charges +
            ')'
          " />

        <div class="space-y-3 py-3">
          <app-alert-error v-if="errorsPayment.enabled" :message="errorsPayment.enabled[0]" />
          <div class="space-y-3 p-3 border rounded-md bg-gray-100" :class="{ 'bg-red-50': errorsPayment.enabled }">
            <div class="flex justify-between">
              <p class="text-sm">
                {{ $t('payment.menu.enable_payment_gateway') }}
              </p>
              <app-form-switch v-model="enabledBusinessPaymentGatewayMethod" />
            </div>
          </div>
        </div>

        <app-button :showf70Icon="false" class="mt-4 pt-2 pb-2" @onClick="submitBusinessPaymentGatewayMethod">
          {{ $t('general.submit') }}
        </app-button>
      </div>

      <!-- Business Payment Gateway Config  --->

      <div v-else-if="selectBusinessPaymentProviderTab == this.TAB.CONFIGS">

        <div class="space-y-3" v-if="selectBusinessPaymentGateway?._id">

          <!-- For Stripe -->
          <div v-if="selectPaymentGatewayApplication?.payment_gateway_method?.channel == PAYMENT_CHANNEL.STRIPE"
            class="space-y-5">

            <app-alert-error :message="errorsPaymentMethodMessage" class="mb-5" />

            <div class="flex space-x-2" v-if="selectPaymentGatewayApplication.stripe_status">
              <app-badge class="flex-1 py-2" :status="stripeStatus ? 'success' : 'warning'">
                <div class="flex space-x-3">
                  <p>{{ $t('general.status') }} : {{ stripeStatus ? 'Verified' : 'Pending' }}</p>
                  <app-icon-loading v-if="loadingPaymentMethod" iconColor="gray-600" customClass="w-4 h-4" />
                  <app-icon v-else @click="statusConnectStripeAccount" name="RefreshIcon" class="w-3 cursor-pointer" />
                </div>
              </app-badge>

              <app-button v-if="!stripeStatus" width="w-auto" class="flex-2 pt-2 pb-2" customClass="p-10" :showf70Icon="false"
                :loading="loadingPaymentMethod" @click="showModalConfirmConnectStripe = true">
                <p class="px-3 text-xs">{{ $t('payment.config.stripe.resubmit_to_stripe') }}</p>
              </app-button>
            </div>

            <div v-else class="space-y-4">
              <div class="space-y-2">
                <p class="font-semibold">{{ $t('payment.config.stripe.send_document') }}</p>
                <p class="text-sm text-gray-500">{{ $t('payment.config.stripe.send_document_desc') }}</p>
              </div>
              <app-button width="w-auto" class="pt-2 pb-2" customClass="p-10" :showf70Icon="false"
                :loading="loadingPaymentMethod" @click="showModalConfirmConnectStripe = true">
                <p class="px-3 text-xs">{{ $t('payment.config.stripe.connect_to_stripe') }}</p>
              </app-button>
            </div>
            <hr>
          </div>

          <!-- Field input Config -->
          <app-form-input id="merchant_id" name="merchant_id" type="text"
            :placeholder="$t('payment.config.form_merchant_id_placeholder')"
            v-model="business_payment_gateway_config.merchant_id" :errorMessage="errorsPayment.merchant_id"
            :disabled="loadingPaymentMethod" :labelText="
              $t('payment.config.form_merchant_id') + ' * '
            " />

          <app-form-input id="secret_key" name="secret_key" type="text"
            :placeholder="$t('payment.config.form_secret_key_placeholder')"
            v-model="business_payment_gateway_config.secret_key" :errorMessage="errorsPayment.secret_key"
            :disabled="loadingPaymentMethod" :labelText="
              $t('payment.config.form_secret_key') + ' * '
            " />

          <app-form-input id="api_key" name="api_key" type="text"
            :placeholder="$t('payment.config.form_api_key_placeholder')"
            v-model="business_payment_gateway_config.api_key" :errorMessage="errorsPayment.api_key"
            :disabled="loadingPaymentMethod" :labelText="
              $t('payment.config.form_api_key')
            " />
          <div class="h-3" />

          <app-button :disabled="loadingPaymentMethod" :showf70Icon="false" class="pt-2 pb-2"
            @onClick="submitBusinessPaymentGatewayConfig">
            {{ $t('general.submit') }}
          </app-button>

        </div>
        <div class="py-10 space-y-5 text-center" v-else>
          <p class="text-center text-gray-600">
            {{ $t('payment.config.no_payment_gateway_charges') }}
          </p>
          <app-button width="w-auto" class="pt-2 pb-2" :showf70Icon="false"
            @click="selectBusinessPaymentProviderTab = this.TAB.CHARGES">
            {{ $t('payment.config.update_payment_charges') }}
          </app-button>
        </div>
      </div>


      <!--Display Stripe Status-->
      <div class="space-y-3" v-else-if="selectBusinessPaymentProviderTab == this.TAB.STRIPE">
        <div class="space-y-3" v-if="selectBusinessPaymentGateway?._id">
          <div class="
            block
            whitespace-pre
            bg-gray-900
            text-yellow-200 text-xs
            p-2
            mb-3
            rounded-md
            col-span-3
          ">
            <code v-text="selectPaymentGatewayApplication.stripe_status" :status="stripeStatus" />
          </div>
        </div>
        <div class="py-10 space-y-5 text-center" v-else>
          <p class="text-center text-gray-600">
            {{ $t("payment.config.stripe.no_stripe_status") }}
          </p>
        </div>
      </div>
    </template>
  </app-modal>



  <!-- Modal Confirmation send document -->
  <app-modal :show="showModalConfirmConnectStripe" @close="showModalConfirmConnectStripe = false"
    :showCancelButton="true" :showClose="false" width='max-w-md w-full' textButtonCancel="Skip for now">
    <template v-slot:title>
      {{ $t('payment.config.stripe.modal_title_confirmation') }}
    </template>

    <template v-slot:body>
      <p class="text-gray-400">
        {{ $t('payment.config.stripe.modal_description_confirmation') }}
      </p>
    </template>

    <template v-slot:action>
      <app-button width="w-auto" class="ml-3" :showf70Icon="false" @click="submitConnectStripeAccount">
        <p>
          {{ $t('payment.config.stripe.modal_action_confirmation') }}
        </p>
      </app-button>
    </template>
  </app-modal>

  <!-- Payment Gateway Methods - approved  --->
  <div v-if="!$lodash.isEmpty(businessPaymentGatewayMethods)" class="mb-10 space-y-3">
    <p class="font-medium">{{ $t('payment.menu.approved_payment_gateway') }}</p>
    <app-alert-error v-if="errorsPayment.enabled" class="p-4 bg-error-badge" :message="errorsPayment.enabled[0]" />
    <div v-for="([paymentMethodName, paymentMethodId], paymentMethod) in Object.entries(PAYMENT_METHODS)"
      :key="paymentMethod">
      <div v-if="checkBusinessHasPaymentMethods(businessPaymentGatewayMethods, paymentMethodId)">
        <p class="text-xs text-gray-500 pb-2">{{ paymentMethodName?.replaceAll('_', ' ') }}</p>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-5">
          <card-payment-provider v-for="businessPaymentGatewayMethod in businessPaymentGatewayMethods"
            :key="businessPaymentGatewayMethod._id" :data="businessPaymentGatewayMethod"
            @edit="showBusinessPaymentGatewayMethod" :showChannel="true"
            v-show="checkBusinessHasPaymentMethod(businessPaymentGatewayMethod, paymentMethodId)"
            class="cursor-pointer" />
        </div>
      </div>
    </div>
  </div>

  <!-- Payment Gateway Application - pending/review  --->
  <div v-if="!$lodash.isEmpty(paymentGatewayApplications)" class="mb-10">
    <p class="mb-3 font-medium">{{ $t('payment.menu.under_review') }}</p>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <card-payment-provider-pending v-for="paymentGatewayApplication in paymentGatewayApplications"
        :key="paymentGatewayApplication._id" :data="paymentGatewayApplication"
        @onClick="showPaymentGatewayApplication" />
    </div>
  </div>

  <div v-if="hasPaymentGateways" class="flex flex-col items-center my-16 space-y-3">
    <img class="w-32" src="@/assets/image/default-payment-provider.svg" />
    <span class="text-center font-bold">
      {{ $t("payment.settings.payment_provider.list_payment_provider") }}
    </span>

    <!-- Button Generate Payment Provider Gateway For Test Mode  --->
    <div v-if="testMode" class="flex flex-col items-center pt-3 mb-14">
      <app-button width="w-auto" class="space-x-3" :showf70Icon="false" @click="generatePaymentProvider">
        <app-icon name="RefreshIcon" class="w-5" />
        <p>{{ $t("payment.test_mode.generete_payment_provider") }}</p>
      </app-button>
    </div>
  </div>

  <!-- Button Add Payment Gateway  --->
  <div class="grid grid-cols-1 gap-6">
    <app-button-outline class="flex items-center pt-6 pb-6 h-14 space-x-2" @onClick="showModalAddPaymentGateway">
      <app-icon name="PlusCircleIcon" class="w-6" />
      <p class="">{{ $t('payment.menu.add_payment_gateway') }}</p>
    </app-button-outline>
  </div>
</template>

<script>
import PAYMENT_CHANNEL from '@/utils/const/payment_channel'
import PAYMENT_METHODS from '@/utils/const/payment_methods'
import PAYMENT_CHARGE_TYPE from '@/utils/const/payment_charge_type'
import PAYMENT_GATEWAY_APPLICATION from '@/utils/const/payment_gateway_application_status'
import cardPaymentProvider from '../../payment/items/card-payment-provider.vue'
import cardPaymentProviderPending from '../../payment/items/card-payment-provider-pending.vue'

export default {
  components: { cardPaymentProvider, cardPaymentProviderPending },
  props: ['menu_action', 'edit_mode'],
  data() {
    return {
      PAYMENT_METHODS: PAYMENT_METHODS,
      PAYMENT_CHANNEL: PAYMENT_CHANNEL,
      PAYMENT_CHARGE_TYPE: PAYMENT_CHARGE_TYPE,
      PAYMENT_GATEWAY_APPLICATION: PAYMENT_GATEWAY_APPLICATION,

      showFormPaymentProvider: false,
      showFormBusinessPaymentProvider: false,
      showModalConfirmConnectStripe: false,

      selectPaymentGatewayApplication: null,
      selectBusinessPaymentGateway: null,

      selectedChargesTypeFixed: false,
      selectedChargesTypePercentage: false,

      selectBusinessPaymentProviderTab: null,

      payment_gateway_method_application: {
        select_payment_gateway: null,
        select_payment_method: null,
        select_payment_channel: null,
        select_payment_gateway_application_status: null
      },
      business_payment_gateway_method: {
        settlement_day: null,
        fixed_charges: null,
        percentage_charges: null,
        charges_type: null,
        fixed_min_charges: null,
        percentage_min_charges: null,
        min_charges_type: null,
        enabled: null
      },
      business_payment_gateway_config: {
        merchant_id: null,
        secret_key: null,
        api_key: null,
      },
      TAB: {
        CHARGES: 'CHARGES',
        CONFIGS: 'CONFIGS',
        STRIPE: 'STRIPE'
      }
    }
  },
  computed: {
    message() {
      return this.$store.getters['paymentMethodStore/message']
    },

    loadingPayment() {
      return this.$store.getters['paymentStore/loading']
    },

    loadingPaymentMethod() {
      return this.$store.getters['paymentMethodStore/loading']
    },

    errorsPayment() {
      return this.$store.getters['paymentStore/errors']
    },

    errorsPaymentMessage() {
      return this.$store.getters['paymentStore/message']
    },

    errorsPaymentMethodMessage() {
      return this.$store.getters['paymentMethodStore/message']
    },
    errorsPaymentMethod() {
      return this.$store.getters['paymentMethodStore/errors']
    },

    business() {
      return this.$store.getters['businessStore/business']
    },

    chargesType() {
      return this.$store.getters['paymentStore/chargesType']
    },

    loadingChargesType() {
      return this.$store.getters['paymentStore/loadingChargesType']
    },

    testMode() {
      return this.$store.getters["businessStore/test_mode"] ?? false;
    },

    businessPaymentGatewayMethods() {
      return this.$store.getters['paymentStore/business_payment_gateway_method']
    },

    paymentGatewayApplications() {
      let payment_gateway_applications = this.$store.getters['paymentMethodStore/payment_gateway_applications']
      return this.$lodash.filter(payment_gateway_applications, (paymnet_gateway_application) => {
        let isEmptyPayment = this.$lodash.isEmpty(paymnet_gateway_application?.business_payment_gateway_methods);
        let isApplicationApproved = this.checkPaymentGatewayApplicationStatus(paymnet_gateway_application);
        return isEmptyPayment || !isApplicationApproved
      })
    },

    business_payment_provider_tabs() {
      return (this.selectPaymentGatewayApplication?.payment_gateway_method?.channel == PAYMENT_CHANNEL.STRIPE) ?
        [
          { key: this.TAB.CHARGES, title: this.$t('payment.menu.charges') },
          { key: this.TAB.CONFIGS, title: this.$t('payment.menu.configs') },
          { key: this.TAB.STRIPE, title: this.$t('payment.menu.stripe') }
        ] :
        [
          { key: this.TAB.CHARGES, title: this.$t('payment.menu.charges') },
          { key: this.TAB.CONFIGS, title: this.$t('payment.menu.configs') },
        ];
    },

    stripeStatus() {
      return this.selectPaymentGatewayApplication?.stripe_status.payouts_enabled
        && this.selectPaymentGatewayApplication?.stripe_status.charges_enabled;
    },

    enabledBusinessPaymentGatewayMethod: {
      get() {
        return this.business_payment_gateway_method?.enabled == 1
      },
      set(enabled) {
        this.business_payment_gateway_method.enabled = enabled
      }
    },

    hasPaymentGateways() {
      return this.$lodash.isEmpty(this.businessPaymentGatewayMethods) && this.$lodash.isEmpty(this.paymentGatewayApplications);
    }
  },

  created() {
    this.fetchBusinessPaymentGatewayMethod()
    this.fetchPaymentGatewayApplication()
    this.fetchChargesType()
  },

  mounted() {
    this.selectBusinessPaymentProviderTab = this.TAB.CHARGES;
  },

  methods: {
    async submitConnectStripeAccount() {
      this.resetError();
      this.showModalConfirmConnectStripe = false;

      let response = await this.$store.dispatch('paymentMethodStore/connectStripeAccount', {
        payment_gateway_method_application_id: this.selectPaymentGatewayApplication._id ?? null,
        business_id: this.business.id,
      });

      if (this.$lodash.isEmpty(response.errors) && this.$lodash.isEmpty(response.message)) {
        this.selectPaymentGatewayApplication = response.data;
        this.fetchPaymentGatewayConfig();
      }
    },
    async statusConnectStripeAccount() {
      this.resetError();

      let response = await this.$store.dispatch('paymentMethodStore/statusConnectStripeAccount', {
        payment_gateway_method_application_id: this.selectPaymentGatewayApplication._id ?? null,
        business_id: this.business.id,
      });

      if (this.$lodash.isEmpty(response.errors) && this.$lodash.isEmpty(response.message)) {
        this.selectPaymentGatewayApplication = response.data;
        this.fetchPaymentGatewayConfig();
      }
    },
    fetchBusinessPaymentGatewayMethod() {
      this.$store.dispatch('paymentStore/getBusinessPaymentGatewayMethod', this.business?.id)
    },

    fetchPaymentGatewayApplication() {
      this.$store.dispatch('paymentMethodStore/getPaymentGatewayApplications', this.business?.id)
    },

    fetchChargesType() {
      this.$store.dispatch('paymentStore/retrieveChargesType')
    },

    async fetchPaymentGatewayConfig() {

      let paymentGatewayConfig = await this.$store.dispatch('paymentStore/retrieveBusinessPaymentGatewayConfig', {
        business_id: this.business?.id,
        payment_gateway_id: this.selectPaymentGatewayApplication?.payment_gateway_method?.payment_gateway_id,
        channel: this.selectPaymentGatewayApplication?.payment_gateway_method?.channel
      })

      if (paymentGatewayConfig.data && !this.$lodash.isEmpty(paymentGatewayConfig.data)) {
        this.business_payment_gateway_config = paymentGatewayConfig.data
      }

    },

    async submitPaymentMethodApplication() {
      const paymentGatewayApplication = await this.$store.dispatch('paymentMethodStore/createOrUpdatePaymentGatewayApplication', {
        _id: this.selectPaymentGatewayApplication?._id ?? null,
        payment_gateway_application_status_id: this.payment_gateway_method_application.select_payment_gateway_application_status?.id ?? 0,
        payment_gateway_method_id: this.payment_gateway_method_application.select_payment_channel?.id ?? 0,
        business_id: this.business.id,
      })

      if (paymentGatewayApplication && !this.$lodash.isEmpty(paymentGatewayApplication)) {

        this.selectPaymentGatewayApplication = paymentGatewayApplication

        // validate payment gateway application for stripe only
        if ((this.checkPaymentGatewayApplicationStatus(paymentGatewayApplication, PAYMENT_GATEWAY_APPLICATION.CREATED) ||
          this.checkPaymentGatewayApplicationStatus(paymentGatewayApplication, PAYMENT_GATEWAY_APPLICATION.PROCESSING)) &&
          paymentGatewayApplication.payment_gateway_method.channel == PAYMENT_CHANNEL.STRIPE
        ) {
          let business_payment_gateway_method = paymentGatewayApplication?.business_payment_gateway_methods[0]

          if (business_payment_gateway_method) {
            business_payment_gateway_method.payment_gateway_method_application = paymentGatewayApplication
            this.showBusinessPaymentGatewayMethod(business_payment_gateway_method)
          } else {
            this.showFormBusinessPaymentProvider = true;
          }

          // validate payment gateway application approved for ganeral
        } else if (this.payment_gateway_method_application.select_payment_gateway_application_status?.id == PAYMENT_GATEWAY_APPLICATION.APPROVED) {
          if (this.$lodash.isEmpty(paymentGatewayApplication?.business_payment_gateway_methods[0])) {
            this.showFormBusinessPaymentProvider = true;
          }
        }

        this.resetError()
        this.showFormPaymentProvider = false

      } else {
        this.$notify(
          {
            group: "error",
            title: this.message,
          },
          5000
        );
      }

      this.fetchPaymentGatewayApplication()
      this.fetchBusinessPaymentGatewayMethod()
    },

    async submitBusinessPaymentGatewayMethod() {
      const businessPaymentProvider = await this.$store.dispatch('paymentStore/createOrUpdateBusinessPaymentGatewayMethod', {
        payment_gateway_method_application_id: this.selectPaymentGatewayApplication._id ?? null,
        percentage_min_charges: this.business_payment_gateway_method.percentage_min_charges,
        min_charges_type: PAYMENT_CHARGE_TYPE.FIXED,
        percentage_charges: this.business_payment_gateway_method.percentage_charges,
        fixed_min_charges: this.business_payment_gateway_method.fixed_min_charges,
        charges_type: this.business_payment_gateway_method.charges_type?.type,
        settlement_day: this.business_payment_gateway_method.settlement_day,
        fixed_charges: this.business_payment_gateway_method.fixed_charges,
        enabled: this.enabledBusinessPaymentGatewayMethod ?? false,
        _id: this.selectBusinessPaymentGateway?._id ?? null,
        business_id: this.business.id,
      })

      if (businessPaymentProvider.data && !this.$lodash.isEmpty(businessPaymentProvider.data)) {

        if (!this.business_payment_gateway_config?.merchant_id || !this.business_payment_gateway_config?.secret_key) {

          this.selectBusinessPaymentGateway = businessPaymentProvider.data
          this.selectBusinessPaymentProviderTab = this.TAB.CONFIGS

        } else {

          this.closeFormBusinessPaymentProvider()

          this.$notify(
            {
              group: 'success',
              title: 'Saved!'
            },
            3000
          )

        }

        this.fetchBusinessPaymentGatewayMethod()
        this.fetchPaymentGatewayApplication()
        this.fetchPaymentGatewayConfig()
      }
    },

    async submitBusinessPaymentGatewayConfig() {

      this.business_payment_gateway_config.business_id = this.business?.id
      this.business_payment_gateway_config.channel = this.selectPaymentGatewayApplication?.payment_gateway_method?.channel
      this.business_payment_gateway_config.payment_gateway_id = this.selectPaymentGatewayApplication?.payment_gateway_method?.payment_gateway_id

      const businessPaymentGatewayConfig = await this.$store.dispatch(
        'paymentStore/createOrUpdateBusinessPaymentGatewayConfig',
        this.business_payment_gateway_config
      )

      if (businessPaymentGatewayConfig.data && !this.$lodash.isEmpty(businessPaymentGatewayConfig.data)) {
        this.closeFormBusinessPaymentProvider()
        this.fetchBusinessPaymentGatewayMethod()
        this.fetchPaymentGatewayApplication()
        this.$notify(
          {
            group: 'success',
            title: 'Saved!'
          },
          3000
        )
      }
    },

    showModalAddPaymentGateway() {
      this.resetForm()
      this.resetError()
      this.showFormPaymentProvider = true
      this.selectBusinessPaymentGateway = null
      this.selectPaymentGatewayApplication = null
    },

    showPaymentGatewayApplication(paymentGatewayApplication) {
      this.resetError()
      this.showFormPaymentProvider = true
      this.selectPaymentGatewayApplication = paymentGatewayApplication
      this.payment_gateway_method_application = {
        select_payment_gateway: paymentGatewayApplication.payment_gateway_method.payment_gateway,
        select_payment_method: paymentGatewayApplication.payment_gateway_method,
        select_payment_channel: paymentGatewayApplication.payment_gateway_method,
        select_payment_gateway_application_status: paymentGatewayApplication.payment_gateway_application_status
      }
    },

    showBusinessPaymentGatewayMethod(businessPaymentGatewayMethod) {
      this.resetForm()
      this.resetError()
      this.showFormBusinessPaymentProvider = true
      this.selectPaymentGatewayApplication = businessPaymentGatewayMethod.payment_gateway_method_application
      this.selectBusinessPaymentGateway = businessPaymentGatewayMethod
      this.business_payment_gateway_method = {
        fixed_charges: businessPaymentGatewayMethod.fixed_charges,
        percentage_charges: businessPaymentGatewayMethod.percentage_charges,
        charges_type: {
          id: businessPaymentGatewayMethod.charges_type,
          name: this.$t('payment.menu.' + businessPaymentGatewayMethod.charges_type),
          type: businessPaymentGatewayMethod.charges_type
        },
        fixed_min_charges: businessPaymentGatewayMethod.fixed_min_charges,
        percentage_min_charges: businessPaymentGatewayMethod.percentage_min_charges,
        min_charges_type: {
          id: businessPaymentGatewayMethod.min_charges_type,
          name: this.$t('payment.menu.' + businessPaymentGatewayMethod.min_charges_type),
          type: businessPaymentGatewayMethod.min_charges_type
        },
        settlement_day: businessPaymentGatewayMethod.settlement_day,
        enabled: businessPaymentGatewayMethod.enabled
      }
      this.fetchPaymentGatewayConfig()
    },

    closeFormPaymentProvider() {
      this.resetForm()
      this.resetError()
      this.showFormPaymentProvider = false
    },

    closeFormBusinessPaymentProvider() {
      this.resetForm()
      this.resetError()

      this.showFormBusinessPaymentProvider = false;
      this.selectPaymentGatewayApplication = null
      this.selectBusinessPaymentGateway = null
    },

    checkBusinessHasPaymentMethod(businessPaymentGatewayMethod, paymentMethodId) {
      let hasPaymentMethod = businessPaymentGatewayMethod?.payment_gateway_method_application?.payment_gateway_method?.payment_method_id == paymentMethodId
      let hasPaymentGatewayApproved = this.checkPaymentGatewayApplicationStatus(businessPaymentGatewayMethod?.payment_gateway_method_application)
      return hasPaymentMethod && hasPaymentGatewayApproved
    },

    checkPaymentGatewayApplicationStatus(paymnet_gateway_application, status = PAYMENT_GATEWAY_APPLICATION.APPROVED) {
      return paymnet_gateway_application?.payment_gateway_application_status_id == status;
    },

    checkBusinessHasPaymentMethods(businessPaymentGatewayMethods, paymentMethodId) {
      return businessPaymentGatewayMethods.find(
        (businessPaymentGatewayMethod) =>
          businessPaymentGatewayMethod?.payment_gateway_method_application?.payment_gateway_method?.payment_method_id == paymentMethodId &&
          this.checkPaymentGatewayApplicationStatus(businessPaymentGatewayMethod?.payment_gateway_method_application)
      )
    },

    async generatePaymentProvider() {
      const generateTestModePaymentProvider = await this.$store.dispatch(
        "integrationStore/generateTestModePaymentProvider",
        this.business.id
      );

      if (this.$lodash.isEmpty(generateTestModePaymentProvider.errors)) {
        this.$notify(
          {
            group: "success",
            title: this.$t('payment.test_mode.generete_payment_provider_success'),
          },
          3000
        );
      }
    },

    resetForm() {
      this.payment_gateway_method_application = {
        select_payment_gateway: null,
        select_payment_method: null,
        select_payment_channel: null,
        select_payment_gateway_application_status: null
      }

      this.business_payment_gateway_method = {
        fixed_charges: null,
        settlement_day: 3, // default settlement day
        percentage_charges: null,
        charges_type: null,
        fixed_min_charges: null,
        percentage_min_charges: null,
        min_charges_type: null,
        enabled: null
      }

      this.business_payment_gateway_config = {
        merchant_id: null,
        secret_key: null,
        api_key: null,
      }
    },

    resetError() {
      this.$store.commit('paymentMethodStore/setErrorMessage')
      this.$store.commit('paymentStore/setErrorMessage')
    }
  }
}
</script>